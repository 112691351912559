import React, { Component } from "react"
import { Link } from "gatsby"
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa"
import { FiX, FiMenu } from "react-icons/fi"
import Scrollspy from "react-scrollspy"

import Logo from "../images/logo_1.png"
import LogoText from "../images/logo_2.png"

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
]

class Header extends Component {
  constructor(props) {
    super(props)
    this.isBrowser() && window.addEventListener("load", function () {})
  }

  isBrowser = () =>
    typeof window !== "undefined" && typeof document !== "undefined"

  menuTrigger = () => {
    this.isBrowser() &&
      document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  closeMenuTrigger = () => {
    this.isBrowser() &&
      document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader = () => {}

  render() {
    if (this.isBrowser()) {
      window.addEventListener("scroll", function () {
        const scrollPosition = window.scrollY
        if (scrollPosition > 50) {
          document.querySelector(".header--fixed").classList.add("sticky")
        } else {
          document.querySelector(".header--fixed").classList.remove("sticky")
        }
      })
    }

    const linksHasSubMenu =
      this.isBrowser() && document.querySelectorAll(".has-droupdown > a")
    for (let i in linksHasSubMenu) {
      if (linksHasSubMenu.hasOwnProperty(i)) {
        linksHasSubMenu[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active")
          this.classList.toggle("open")
        }
      }
    }

    const { logo, color = "default-color" } = this.props
    // @todo
    const logoUrl = (
      <>
        <img className="logo-1" src={Logo} alt="Tekmens" />
        <img className="logo-2" src={LogoText} alt="Tekmens" />
      </>
    )

    return (
      <header className={`header-area formobile-menu header--fixed ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          {/* Logo */}
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          {/* Main Menu */}
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="has-droupdown">
                  <Link to="/sectors">Our Sectors</Link>
                  {/* <ul className="submenu">
                    <li>
                      <Link to="/sectors/environment">
                        Environmental Solutions
                      </Link>
                    </li>
                    <li>
                      <Link to="/sectors/mep">MEP</Link>
                    </li>
                    <li>
                      <Link to="/sectors/construction">
                        Construction Equipments
                      </Link>
                    </li>
                    <li>
                      <Link to="/sectors/software">IT &amp; Software</Link>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <Link to="/contact" className="rn-btn">
                <span>Contact</span>
              </Link>
            </div>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.closeMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default Header
